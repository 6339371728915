<template>
  <div>
    <b-row>
      <b-col
        xl="2"
        md="4"
        sm="6"
      >
        <b-card
          v-if="loading"
          class="text-center"
        >
          <b-spinner label="Loading..." />
        </b-card>
        <statistic-card-vertical
          v-else
          color="secondary"
          icon="UserPlusIcon"
          :statistic="readableNum(stats.prospects_count)"
          statistic-title="Prospects"
        />
      </b-col>
      <b-col
        xl="2"
        md="4"
        sm="6"
      >
        <b-card
          v-if="loading"
          class="text-center"
        >
          <b-spinner label="Loading..." />
        </b-card>
        <statistic-card-vertical
          v-else
          color="success"
          icon="UsersIcon"
          :statistic="readableNum(stats.prospects_slots_sum)"
          statistic-title="Places"
        />
      </b-col>
      <b-col
        xl="2"
        md="4"
        sm="6"
      >
        <b-card
          v-if="loading"
          class="text-center"
        >
          <b-spinner label="Loading..." />
        </b-card>
        <statistic-card-vertical
          v-else
          color="warning"
          icon="DollarSignIcon"
          :statistic="`${readableNum(stats.prospects_slots_sum * 2)}€`"
          statistic-title="Euros"
        />
      </b-col>
    </b-row>
    <b-card no-body>
      <div class="m-2">
        <b-row>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Afficher</label>
            <v-select
              v-model="perPage"
              :dir="'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
              @input="fetchProspects(true)"
            />
            <label>entrées</label>
          </b-col>
          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <b-form @submit.prevent="fetchProspects(true)">
              <div class="d-flex align-items-center justify-content-end">
                <b-form-input
                  v-model="searchQuery"
                  class="d-inline-block mr-1"
                  placeholder="Rechercher..."
                />
                <b-button
                  type="submit"
                  variant="primary"
                >
                  <span class="text-nowrap">Chercher</span>
                </b-button>
              </div>
            </b-form>
          </b-col>
        </b-row>
      </div>
      <b-table
        ref="refProspectListTable"
        class="position-relative"
        :items="prospects"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="Aucun résultat trouvé"
        :sort-desc.sync="isSortDirDesc"
        :no-local-sorting="true"
        :busy="loading"
        @sort-changed="sortProspects($event)"
      >
        <!-- busy -->
        <template #table-busy>
          <div class="text-center my-2">
            <b-spinner label="Loading..." />
          </div>
        </template>

        <template #cell(competition)="data">
          {{ data.value.name }}
        </template>

        <!-- Column: created_at -->
        <template #cell(created_at)="data">
          <span>{{ moment(data.value).format("LLL") }}</span>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <div class="text-nowrap">
            <feather-icon
              :id="`prospect-row-${data.item.id}-delete-icon`"
              v-b-modal="`modal-delete-prospect-${data.item.id}`"
              icon="TrashIcon"
              class="cursor-pointer"
              size="16"
            />
            <b-tooltip
              title="Supprimer"
              class="cursor-pointer"
              :target="`prospect-row-${data.item.id}-delete-icon`"
            />
            <b-modal
              :id="`modal-delete-prospect-${data.item.id}`"
              :ref="`modal-delete-prospect-${data.item.id}`"
              ok-variant="danger"
              ok-title="Supprimer"
              modal-class="modal-danger"
              centered
              title="Suppression du prospect"
            >
              <b-card-text>
                Souhaitez vous vraiment supprimer ce prospect?<br>
                <p class="text-danger font-weight-bold">
                  (CETTE ACTION EST IRRÉVERSIBLE)
                </p>
              </b-card-text>
              <template #modal-footer>
                <b-button
                  variant="secondary"
                  @click="hideModal(`modal-delete-prospect-${data.item.id}`)"
                >
                  annuler
                </b-button>
                <b-button
                  variant="danger"
                  :disabled="deleteLoading"
                  @click="deleteProspect(data.item.id)"
                >
                  <b-spinner
                    v-show="deleteLoading"
                    small
                  />
                  supprimer
                </b-button>
              </template>
            </b-modal>
          </div>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span
              class="text-muted"
            >Affichage de {{ dataMeta.from }} à {{ dataMeta.to }} sur
              {{ dataMeta.total }} entrées</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalProspects"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              @change="changePage($event)"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BButton,
  BCard, BCardText,
  BCol, BForm,
  BFormInput,
  BModal,
  BPagination,
  BRow, BSpinner,
  BTable, BTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import axiosIns from '@/libs/axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical'
import { readableNum } from '@/utils'

export default {
  name: 'ProspectsList',
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BPagination,
    BSpinner,
    BForm,
    vSelect,
    BTooltip,
    BModal,
    BCardText,
    StatisticCardVertical,
  },
  data() {
    return {
      loading: true,
      deleteLoading: false,
      perPageOptions: [5, 25, 50, 100],
      perPage: 25,
      searchQuery: '',
      tableColumns: [
        {
          key: 'company',
          label: 'Entreprise',
          sortable: true,
        },
        {
          key: 'name',
          label: 'Nom',
          sortable: true,
        },
        {
          key: 'email',
          label: 'Email',
          sortable: true,
        },
        {
          key: 'phone',
          label: 'Tél',
          sortable: true,
        },
        {
          key: 'slot',
          label: 'Places',
          sortable: true,
        },
        {
          key: 'competition',
          label: 'Compétition',
          sortable: true,
        },
        {
          key: 'created_at',
          label: 'Date de création',
          sortable: true,
        },
        {
          key: 'actions',
        },
      ],
      sortBy: 'created_at',
      isSortDirDesc: true,
      prospects: [],
      stats: {},
      currentPage: 1,
      totalProspects: 0,
      dataMeta: {
        from: 0,
        to: 0,
        of: 0,
        total: 0,
      },
      readableNum,
    }
  },
  mounted() {
    this.fetchProspects()
  },
  methods: {
    async fetchProspects(clearPagination = false) {
      try {
        this.loading = true
        const { data } = await axiosIns.get('/prospects', {
          params: {
            term: this.searchQuery,
            page: clearPagination ? 1 : this.currentPage,
            perPage: this.perPage,
            sortBy: this.sortBy,
            sortDirection: this.isSortDirDesc ? 'desc' : 'asc',
          },
        })
        this.stats = data.stats
        this.prospects = data.data
        this.dataMeta.from = data.meta.from
        this.dataMeta.to = data.meta.to
        this.dataMeta.total = data.meta.total
        this.totalProspects = data.meta.total
        this.loading = false
      } catch (err) {
        this.loading = false
        console.log(err)
        this.$toast(
          {
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Erreur',
              icon: 'AlertCircleIcon',
              variant: 'danger',
              text: `Une erreur s'est produite. erreur: ${err}`,
            },
          },
          { timeout: 5000 },
        )
      }
    },
    async deleteProspect(id) {
      try {
        this.deleteLoading = true
        await axiosIns.delete(`prospect/${id}`)
        this.$toast(
          {
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Enregistré !',
              icon: 'CheckCircleIcon',
              variant: 'success',
              text: 'Le prospect a été supprimé',
            },
          },
          { timeout: 5000 },
        )
        this.deleteLoading = false
        this.hideModal(`modal-delete-prospect-${id}`)
        this.fetchProspects()
      } catch (err) {
        this.$toast(
          {
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Erreur',
              icon: 'AlertCircleIcon',
              variant: 'danger',
              text: `Une erreur s'est produite. erreur: ${err}`,
            },
          },
          { timeout: 5000 },
        )
        this.deleteLoading = false
      }
    },
    sortProspects(e) {
      this.isSortDirDesc = e.sortDesc
      this.sortBy = e.sortBy
      this.fetchProspects()
    },
    changePage(e) {
      this.currentPage = e
      this.fetchProspects()
    },
    hideModal(ref) {
      this.$refs[ref].hide()
    },
  },
}
</script>

<style lang="scss">
.per-page-selector {
  width: 90px;
}
@import "@core/scss/vue/libs/vue-select.scss";
</style>
